@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap');

body {
  margin: 0;
  background-color: antiquewhite;
  font-family: 'Roboto', sans-serif;
}


h1, h2, h3, h4 {
  margin: 0;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

* {
  box-sizing: border-box;
}

p {
  padding: 0;
  margin: 0;
}