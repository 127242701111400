div.App {
    width: 800px;
    height: 100%;
    margin: auto;

}

@media screen and (max-width: 992px) {
    div.App {
        width: 90%;
    }   
}

header {
    height: 200px;
    padding: 40px 0;

    text-align: center;
    color: #181818;

    display: flex;
    justify-content: center;
    flex-direction: column;
}

header h1 {
    font-size: 40px;
    margin: 10px 0;
}

header h2 {
    top: 10px;
    font-size: 26px;
}

ol, ul {
    list-style: none;
    padding: 0;
}