div.link-group {
    margin-top: 30px;

    p.group-title {
        font-size: 18px;
        padding: 5px 0px;
    
        color: #181818;
    }

    hr {
        margin-bottom: 15px;
    }

    ol.link-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        
        padding: 0 15px 0 15px;

        > li {
            width: 370px;
            a.link-element {
                display: flex;
                padding: 20px;
                gap: 20px;
            
                position: relative;
                align-items: center;
            

                height: 80px;
            
                margin: 8px 0;
            
                background-color: white;
                border-radius: 20px;
            
                color: black;
                text-decoration: none;
            
                > div.link-info {
                    p.link-title {
                        position: relative;
                        top: 1px;
                        font-weight: bold;
                        margin-bottom: 5px;
                    }
            
                    p.link-description {
                        font-size: 14px;
                    }
                }
                
                img{
                    height: 100%;
                    width: 60px;
                    object-fit: contain; 
                }
                
                &:hover {
                    color: white;
                
                    background-color: rgb(30, 30, 30);
                    cursor: pointer;
                }
                &:hover img{
                    filter: invert(100) grayscale(100) brightness(2);
                }    
            }
        }
    }
}

